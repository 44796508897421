import { configureStore } from "@reduxjs/toolkit";
import faqsReducer from "./faqs";
import loansReducer from "./loans";
import loansReqReducer from "./loansReq";
import dueRepaymentsReducer from "./dueRepayments";
import borrowersReducer from "./borrowers";
import opportunitiesReducer from "./opportunities";
import fundsManagementReducer from "./fundsManagment";
import withdrawalReqReducer from "./withdrawalReq";
import usersReducer from "./users";
import investorsReducer from "./investors";
import generalReducer from "./general";
import financialAdvisorReducer from "./financialAdvisor";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    faqsReducer,
    loansReducer,
    loansReqReducer,
    dueRepaymentsReducer,
    borrowersReducer,
    opportunitiesReducer,
    fundsManagementReducer,
    withdrawalReqReducer,
    usersReducer,
    investorsReducer,
    generalReducer,
    financialAdvisorReducer
  },
});
