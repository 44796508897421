// ! ----------------------------- imports start ---------------------------------- //
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Button, Tabs, Tag, Table, Select, Progress, Spin, Modal, Divider } from "antd";
import { CheckCircleOutlined, FieldTimeOutlined } from "@ant-design/icons";
import Layout from "../../sharedModules/defaultLayout";
import { commaSeparator } from "../../utils/Helper";
import SupportedDocuments from "../Investors/RequestedInvestors/SuppportedDocuments";
import CancelOpportunity from "../../Components/forms/Opportunity/CancelOpportunity";
//api
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { getSingleOpportunity } from "../../store/opportunities";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;
// ! ----------------------------- imports end ---------------------------------- //

const Detail = () => {
  // ^ ----------------------------- constants start ---------------------------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelOpportunityModal, setCancelOpportunityModal] = useState(false);
  const [loading, setLoading] = useState([]);
  const [opportunityDetail, setOpportunityDetail] = useState(null);
  const { singleOpportunity, singleOpportunityLoading } = useSelector(
    (state) => ({
      singleOpportunity: state.opportunitiesReducer.singleOpportunity,
      singleOpportunityLoading: state.opportunitiesReducer.singleOpportunityLoading,
    }),
    shallowEqual
  );

  const investmentsCols = [
    {
      title: "Name",
      dataIndex: "investor_name",
      key: "investor_name",
      render: (investor_name) => investor_name || "__",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (amount && `${commaSeparator(amount)} SAR`) || "-",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span className={` py-1 px-2 tag-status tag-status__${status}`}>
          {status}
        </span>
      ),
    },
  ];
  const repaymentCols = [
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => dayjs(created_at).format("YYYY-MM-DD"),
    },
    {
      title: "Total payment(SAR)",
      dataIndex: "total_payment",
      key: "total_payment",
      render: (total_payment) => total_payment && commaSeparator(total_payment),
    },
    {
      title: "Payment date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date) => dayjs(payment_date).format("YYYY-MM-DD"),
    },
    {
      title: "Principal(SAR)",
      dataIndex: "principal",
      key: "principal",
      render: (principal) => commaSeparator && commaSeparator(principal),
    },
    {
      title: "Net periodic return(SAR)",
      dataIndex: "net_periodic_return",
      key: "net_periodic_return",
      render: (net_periodic_return) => net_periodic_return && commaSeparator(net_periodic_return),
    },
  ];
  const tabsItems = [
    {
      label: "Investments",
      key: "0",
      children: (
        <Table
          size="small"
          columns={investmentsCols}
          pagination={false}
          dataSource={singleOpportunity?.investments}
          rowKey="id"
        />
      ),
    },
    {
      label: "Repayments",
      key: "1",
      children: (
        <Table
          size="small"
          columns={repaymentCols}
          pagination={false}
          dataSource={singleOpportunity?.repayments}
          rowKey="id"
        />
      ),
    },
  ]
  // ^ ----------------------------- constants end ---------------------------------- //

  // & ----------------------------- methods start ---------------------------------- //

  useEffect(() => {
    dispatch((getSingleOpportunity(params.id)))
  }, []);

  const cancelOpportunityHandler = () => {
    setCancelOpportunityModal(!cancelOpportunityModal)
  }
  // & ----------------------------- methods start ---------------------------------- //
  let opportunitiesStatusStyle = "";
  if (opportunityDetail?.opportunity_status === "active") {
    opportunitiesStatusStyle = "processing";
  } else if (opportunityDetail?.opportunity_status === "inactive") {
    opportunitiesStatusStyle = "default";
  } else if (opportunityDetail?.opportunity_status === "defaulted") {
    opportunitiesStatusStyle = "red";
  } else if (opportunityDetail?.opportunity_status === "comingsoon") {
    opportunitiesStatusStyle = "orange";
  } else if (opportunityDetail?.opportunity_status === "completed") {
    opportunitiesStatusStyle = "purple";
  }


  console.log(opportunityDetail, "opportunity details");
  // * ---------------------------- template start --------------------------------- //
  return (
    <div>
      <Spin spinning={singleOpportunityLoading}>
        <Layout sideKey="1">
          <div className="loan-details-page flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <h1 className="text-2xl	font-bold text-spanishRoast mb-3">Details</h1>
              <Tag
                className="mb-3 rounded-full text-center text-xs px-[12px] py-[4px] min-w-[70px]"
                color={opportunitiesStatusStyle}
              >
                {singleOpportunity?.opportunity_status}
              </Tag>
              {singleOpportunity?.loaned == 1 &&
                <Tag
                  className="mb-3 rounded-full text-center text-xs px-[12px] py-[4px] min-w-[70px] bg-primary text-white"
                >
                  converted into loan
                </Tag>
              }
            </div>
            <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
              <Select
                style={{
                  width: "300px",
                }}
                popupMatchSelectWidth={360}
                value={"View Attached Documents"}
              >
                {singleOpportunity?.files?.map((document, i) => (
                  <Option key={i}>
                    <SupportedDocuments document={document} page="opportunity_details" />
                  </Option>
                ))}
              </Select>

              <Button
                type="gray"
                shape="round"
                size={"middle"}
                onClick={() =>
                  navigate(`/opportunity/edit/${singleOpportunity?.id}`)
                }
              >
                Edit Opportunity
              </Button>
            </div>
          </div>

          {singleOpportunity && (
            <>
              {/* --------- 1st section start ---------- */}
              <div className="card-info">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                  className="mb-3"
                >
                  <h3 style={{ fontWeight: "700" }}>#{singleOpportunity?.opportunity_number}</h3>
                  <p
                    className="text-miracle font-medium ml-2 "
                  >
                    {singleOpportunity?.industry_name}
                  </p>
                </div>

                <div className="flex items-center gap-4">
                  <FieldTimeOutlined className="text-primary text-2xl" />
                  <div className="creation-date">
                    <span className="text-xs text-miracle font-semibold">Creation date: </span>
                    <span className="text-xs text-spanishRoast">
                      {(singleOpportunity?.custom_created_at && dayjs(singleOpportunity?.custom_created_at?.split("T")[0]).format("YYYY-MM-DD")) || "----/--/--"}
                    </span>
                  </div>
                  <div className="update-date">
                    <span className="text-xs text-miracle font-semibold">Update date: </span>
                    <span className="text-xs text-spanishRoast">
                      {(singleOpportunity?.updated_at && dayjs(singleOpportunity?.updated_at?.split("T")[0]).format("YYYY-MM-DD")) || "----/--/--"}
                    </span>
                  </div>
                  <div className="due-date">
                    <span className="text-xs text-miracle font-semibold">Due date: </span>
                    <span className="text-xs text-spanishRoast">
                      {(singleOpportunity?.due_date && dayjs(singleOpportunity?.due_date?.split("T")[0]).format("YYYY-MM-DD")) || "----/--/--"}
                    </span>
                  </div>
                </div>

                <Divider />
                <div className="fund-status">
                  <h3 className="text-base font-bold text-spanishRoast">Fund status</h3>
                  <div
                    className="flex justify-between mt-8 mb-3"
                  >
                    <div>
                      <div className="text-primary">
                        <span
                          className="text-base font-bold text-primary"
                        >
                          {(singleOpportunity?.fund_collected && commaSeparator(singleOpportunity?.fund_collected)) || 0}
                        </span>
                        <span
                          className="text-sm font-medium ml-1"
                        >
                          SAR
                        </span>
                      </div>
                      <div
                        className="text-sm font-medium ml-1 text-miracle"
                      >
                        {(singleOpportunity?.fund_collected && singleOpportunity?.fund_needed &&
                          ((singleOpportunity?.fund_collected / singleOpportunity?.fund_needed) * 100).toFixed(2)) || 0
                        }
                        %
                        Collected
                      </div>
                    </div>
                    <div
                      className="flex flex-col justify-between items-end"
                    >
                      <div>
                        <span
                          className="text-sm ml-1 font-extrabold"
                        >
                          {singleOpportunity?.fund_needed && commaSeparator(singleOpportunity?.fund_needed)}
                        </span>
                        <span
                          className="text-sm font-medium ml-1"
                        >
                          SAR
                        </span>
                      </div>
                      <div
                        className="text-xm font-medium text-miracle"
                      >
                        Fund Needed
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <Progress
                      strokeColor="#5B2CD3"
                      //   type=""

                      percent={singleOpportunity?.fund_collected && singleOpportunity?.fund_needed && ((singleOpportunity?.fund_collected / singleOpportunity?.fund_needed) * 100).toFixed(2)}
                      showInfo={false}
                    //   size={[600,15]}
                    />
                    <p className="mt-[2px]">{singleOpportunity?.fund_needed && "100.00%"}</p>
                  </div>
                </div>
              </div>
              {/* --------- 1st section end ---------- */}

              {/* --------- 2nd section start ---------- */}
              <Divider />
              <>
                {singleOpportunity && singleOpportunity?.loaned == 0 && (
                  <div className="flex mb-12">
                    {singleOpportunity?.is_drafted != 1 && (singleOpportunity?.opportunity_status === "comingsoon" || singleOpportunity?.opportunity_status === "completed") &&
                      <Button
                        onClick={() => navigate(`/opportunity/close-opportunity/${singleOpportunity?.id}`)}
                        type="primary"
                        style={{
                          margin: "0 20px",
                          background: "#37b337",
                          fontWeight: "700",
                        }}
                      >
                        Create Loan
                      </Button>
                    }
                    {(singleOpportunity?.opportunity_status !== "defaulted" && singleOpportunity?.opportunity_status !== "cancelled") &&
                      <Button
                        type="primary"
                        onClick={cancelOpportunityHandler}
                        style={{
                          margin: "0 20px",
                          background: "#f04040",
                          fontWeight: "700",
                        }}
                      >
                        Cancel Opportunity
                      </Button>
                    }

                  </div>


                )}
                {/* ---------------- reject modal start ------------- */}
                <Modal
                  width={1200}
                  centered
                  className="logout-modal"
                  open={cancelOpportunityModal}
                  onCancel={cancelOpportunityHandler}
                  footer={false}
                >
                  <CancelOpportunity cancelOpportunityHandler={cancelOpportunityHandler} singleOpportunity={singleOpportunity} />
                </Modal>
                {/* ---------------- reject modal end ------------- */}
              </>
              {/* --------- 2nd section end ---------- */}

              {/* --------- 3rd section start ---------- */}
              <h3 className="text-base font-bold text-spanishRoast">Financing details</h3>
              <Row className="mt-6">
                <Col span={6}>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Annual revenue</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.annual_revenue}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Bank name</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.bank[0]?.name}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">City name</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.city_name}</span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Company legal structure</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.company_legal_structure}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Company location</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.company_location}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Company name</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.company_name}</span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Contact email</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.contact_email}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Contact name</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.contact_name}</span>
                  </div>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">Contact phone number</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.contact_phone_number}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="mb-3">
                    <span className="block text-xs text-miracle">CR number</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.cr_number}</span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Distributed_returns</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.distributed_returns}
                    </span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Establishment_date</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.establishment_date}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row className="mt-6">
                <Col span={6}>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Financing structure</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.financing_structure}
                    </span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Financing type</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.financing_type}
                    </span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">iban</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.iban}</span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Net Annual Return% </span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.net_roi}</span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Repayment period</span>
                    <span className="block font-bold text-sm text-spanishRoast">
                      {singleOpportunity?.repayment_period}
                    </span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Risk score</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.risk_score}</span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">Duration</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.duration}</span>
                  </div>
                  <div className=" mb-3">
                    <span className="block text-xs text-miracle">annual_roi</span>
                    <span className="block font-bold text-sm text-spanishRoast">{singleOpportunity?.annual_roi}</span>
                  </div>
                </Col>
              </Row>

              {/* --------- 3rd section start ---------- */}
              <Divider />
              <div className="mb-6">
                <Tabs
                  defaultActiveKey="0"
                  items={tabsItems}
                />
              </div>
            </>
          )}
        </Layout>
      </Spin>
    </div>
  );
  // * ---------------------------- template end --------------------------------- //
};

export default Detail;
