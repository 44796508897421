import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";

const initialState = {
  // export loans
  usersCSV: null,
  usersCSVLoading: false,
};

//^ ========= get investors csv  ============ //
export const getUsersCSV = createAsyncThunk("users/getUsersCSV", async (data, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`/admin/getadmins/export_csv`)
      .then((response) => {
        console.log(response?.data?.data?.fileUrl, "getUsersCSV");
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        console.log(error);
        return rejectWithValue({
          error: error.message,
        });
      });
  } catch (e) {
    throw e;
  }
});
/*-----------------------Slice----------------*/

const usersSlice = createSlice({
  name: "investorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // investors csv
    builder.addCase(getUsersCSV.pending, (state) => {
      state.usersCSVLoading = true;
    });
    builder.addCase(getUsersCSV.fulfilled, (state, action) => {
      state.usersCSVLoading = false;
      state.usersCSV = action.payload;
    });
    builder.addCase(getUsersCSV.rejected, (state) => {
      state.usersCSVLoading = false;
    });
  },
});

export default usersSlice.reducer;
