import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  TeamOutlined,
  LogoutOutlined,
  MonitorOutlined,
  FundViewOutlined,
  CarryOutOutlined,
  FundFilled,
} from "@ant-design/icons";
import cashIcon from "../../assets/icons/cash.png";
import Vector from "../../assets/icons/Vector.png";
import chatActive from "../../assets/icons/chat-active.png";
import { ReactComponent as Wallet } from "../../assets/svgs/Wallet.svg";
import { ReactComponent as FAQs } from "../../assets/svgs/FAQs.svg";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../utils/Helper";
const Sidebar = ({ sideKey }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const logoutHandler = () => {
  //   Cookies.remove("sessionToken");
  //   navigate("/");
  // };

  return (
    <div className="sidebar">
      <div className="sidebar-body">
        <Menu
          className={"borrower-menu"}
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={[
            {
              key: "/",
              icon: <img src={cashIcon} alt="Cash Icon" />,
              label: <NavLink to="/">Opportunities</NavLink>,
            },
            {
              key: "/investors",
              icon: <TeamOutlined />,
              label: <NavLink to="/investors">Investors</NavLink>,
            },
            {
              key: "/borrowers",
              icon: <TeamOutlined />,
              label: <NavLink to="/borrowers"> Borrowers</NavLink>,
            },

            {
              key: "/loan",
              icon: <img src={cashIcon} alt="Cash Icon" />,
              label: <NavLink to="/loan">Loans</NavLink>,
            },
            {
              key: "/due-repayments",
              icon: <img src={cashIcon} alt="Cash Icon" />,
              label: <NavLink to="/due-repayments">Due Repayments</NavLink>,
            },

            {
              key: "/loan-management",
              icon: <MonitorOutlined />,
              label: <NavLink to="/loan-management"> Loan Requests</NavLink>,
            },

            {
              key: "/withdrawal-request",
              icon: <CarryOutOutlined />,
              label: <NavLink to="/withdrawal-request"> Withdrawal Request</NavLink>,
            },

            {
              key: "/admin-users",
              icon: <img src={Vector} alt="Vector" />,
              label: <NavLink to="/admin-users">Users</NavLink>,
            },

            {
              key: "/financial-advisors",
              icon: <FundViewOutlined />,
              label: <NavLink to="/financial-advisors"> Financial Advisors</NavLink>,
            },
            {
              key: "/funds-management",
              icon: <FundFilled />,
              label: <NavLink to="/funds-management"> Funds Management</NavLink>,
            },
            // {
            //   key: "/wallet-ledger",
            //   icon: <Wallet width={14} height={14} />,
            //   label: <NavLink to="/wallet-ledger">Wallet Ledger</NavLink>,
            // },

            {
              key: "/FAQs",
              icon: <FAQs width={14} height={14} />,
              label: <NavLink to="/FAQs">FAQs</NavLink>,
            },
            // {
            //   key: "/notifications",
            //   icon: <img src={chatActive} alt="chatActive" />,
            //   label: <NavLink to="/notifications"> Notifications</NavLink>,
            // },
          ]}
        />
      </div>
      <div className="sidebar-footer">
        <div onClick={() => logOut(false)} className="flex items-center gap-2 cursor-pointer ms-4 py-2">
          <LogoutOutlined />
          <span>Log out</span>
        </div>

        {/* <Menu 
          mode="vertical" 
          items={[
            {
              key: "/",
              icon: <div ></div>,
              label: <span onClick={logoutHandler}>Log out</span>,
            }
          ]}
          >
        </Menu> */}
      </div>
    </div>
  );
};

export default Sidebar;
