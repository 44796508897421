import React from "react";
import { Table } from "antd";
import "./Table.scss";



const AppTable = ({ dataSource, columns, pagination }) => {
  return (
    <Table
      className="table-setting"
      columns={columns}
      dataSource={dataSource}
      bordered={false}
      pagination={pagination}
    />
  );
};

export default AppTable;
