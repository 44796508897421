import axios, { AxiosError, AxiosResponse, AxiosInstance } from "axios";
import { logOut } from "./../utils/Helper";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_baseURL,
});

axiosInstance.interceptors.request.use((config) => {
  const token2 = localStorage.getItem("adminToken");
  console.log("token", token2);
  //  const token = "19|66uZbbYkNrBUyGgfDaraRkgUHR20nHfLY8SLGiUb";
  if (token2) {
    config.headers.Authorization = `Bearer ${token2}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    //window.location.href = '/';
    const { status } = error.response || {};
    //console.error("Request Error Status Code:", error.response.status);
    switch (status) {
      case 400:
        //console.error("Bad Request:", status);
        break;
      case 401:
        //console.error("Unauthorized:", status);
        logOut(true);
        break;
      case 404:
        //console.error("Not Found:", status);
        break;
      case 500:
        //console.error("Server Error:", status);
        //logOut(true);
        break;
      default:
      //console.error("Unhandled Error:", status);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
